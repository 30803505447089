<template>
  <div class="index-back">
    <img src="../../../static/images/backgroundIndex.png" class="backgroundImg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>
.index-back {
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.backgroundImg {
  object-fit: contain;
  width: 100%;
}
</style>
